import type { SidebarExtensionSDK } from '@contentful/app-sdk';
import { useCMA, useSDK } from '@contentful/react-apps-toolkit';
import { Button, Flex, Spinner } from '@contentful/f36-components';
import { ExternalLinkIcon } from '@contentful/f36-icons';
import { useBuildDeployUrl } from '../hooks/useBuildDeployUrl';
import { useQuery } from 'react-query';

export const DeployLink = () => {
  const sdk = useSDK<SidebarExtensionSDK>();
  const cma = useCMA();
  const buildDeployUrl = useBuildDeployUrl();

  const { data: deployedPath, isLoading } = useQuery(
    'deployUrl',
    async () => {
      const { deployedUrlPath } = sdk.parameters.instance;

      // Recursive function for crawling down into fields, including ones which are links to other entries
      const fieldCrawler = async (
        fields: any,
        fieldPath: string[]
      ): Promise<any> => {
        const field = fields[fieldPath[0]];
        const value = field.getValue?.() ?? field?.['en-US'] ?? field;

        if (value === undefined) {
          throw new Error('Unable to determine field value');
        }

        if (fieldPath.length > 1) {
          if (value?.sys?.type === 'Link') {
            const linkedEntry = await cma.entry.get({ entryId: value.sys.id });
            return fieldCrawler(linkedEntry.fields, fieldPath.slice(1));
          } else {
            return fieldCrawler(value, fieldPath.slice(1));
          }
        } else {
          if (typeof value !== 'string') {
            throw new Error(
              'Final value for a given field path must be a string'
            );
          }

          return value;
        }
      };

      const fieldReplacements = deployedUrlPath.match(/\{[^}]+\}/g);
      const pathReplacements = await Promise.all(
        fieldReplacements.map(async (fieldReplacement: string) => {
          const fieldValue = await fieldCrawler(
            sdk.entry.fields,
            fieldReplacement.slice(1, -1).split('.')
          );

          return [fieldReplacement, fieldValue];
        })
      );

      let outputPath = deployedUrlPath;
      for (const [placeholder, value] of pathReplacements) {
        outputPath = outputPath.replace(placeholder, value);
      }

      return outputPath;
    },
    { retry: false }
  );

  let buttonText;
  if (isLoading) {
    buttonText = (
      <>
        <Flex marginRight="spacing2Xs">Loading</Flex>
        <Spinner />
      </>
    );
  } else {
    buttonText = deployedPath ? 'Visit deployed page' : 'Visit site';
  }

  return (
    <Button
      onClick={() =>
        window.open(buildDeployUrl(deployedPath).toString(), '_blank')
      }
      isDisabled={isLoading}
      isFullWidth
    >
      <Flex alignItems="center">
        <Flex marginRight="spacingS">
          <ExternalLinkIcon />
        </Flex>
        {buttonText}
      </Flex>
    </Button>
  );
};
