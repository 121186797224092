import { Flex, Text } from '@contentful/f36-components';
import { useMemo } from 'react';
import { useBuildDeployUrl } from '../hooks/useBuildDeployUrl';
import { useEntryIsDeployed } from '../hooks/useEntryIsDeployed';
import type { RecentDeployments } from '../types';

interface DeployStatusProps {
  recentDeployments: RecentDeployments;
}

export const DeployStatus = ({ recentDeployments }: DeployStatusProps) => {
  const entryIsDeployed = useEntryIsDeployed(recentDeployments);
  const buildDeployUrl = useBuildDeployUrl();

  const deployStatusProps = useMemo(() => {
    const runDate = new Date(
      recentDeployments.workflow_runs[0].created_at
    ).toLocaleString();
    if (recentDeployments.workflow_runs[0].conclusion === 'success') {
      return {
        fontColor: 'green500',
        children: `Last deploy succeeded - ${runDate}`,
      } as const;
    } else {
      return {
        fontColor: 'red500',
        children: `Last deploy failed - ${runDate}`,
      } as const;
    }
  }, [recentDeployments.workflow_runs]);

  const entryStatusProps = useMemo(() => {
    const { host } = buildDeployUrl();
    if (entryIsDeployed) {
      return {
        fontColor: 'green500',
        children: `${host} is up-to-date for this entry.`,
      } as const;
    } else {
      return {
        fontColor: 'red500',
        children: `${host} is out-of-date for this entry.`,
      } as const;
    }
  }, [entryIsDeployed, buildDeployUrl]);

  return (
    <Flex flexDirection="column">
      <Text
        fontSize="fontSizeS"
        lineHeight="lineHeightS"
        {...deployStatusProps}
      />
      <Text
        fontSize="fontSizeS"
        lineHeight="lineHeightS"
        {...entryStatusProps}
      />
    </Flex>
  );
};
