import type { SidebarExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { useEffect, useMemo, useState } from 'react';
import type { RecentDeployments } from '../types';

export const useEntryIsDeployed = (recentDeployments: RecentDeployments) => {
  const sdk = useSDK<SidebarExtensionSDK>();
  const [entryChangedDate, setEntryChangedDate] = useState<Date>(
    new Date(sdk.entry.getSys().updatedAt)
  );

  useEffect(
    // onSysChanged returns a function that can be called to unsubscribe, which is why that value is being returned from the useEffect callback
    () =>
      sdk.entry.onSysChanged(() =>
        setEntryChangedDate(new Date(sdk.entry.getSys().updatedAt))
      ),
    [sdk]
  );

  return useMemo(
    () =>
      recentDeployments.workflow_runs.some(
        (run) =>
          run.conclusion === 'success' &&
          new Date(run.created_at) > entryChangedDate
      ),
    [recentDeployments, entryChangedDate]
  );
};
