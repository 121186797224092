import { useCallback, useState, useEffect } from 'react';
import { AppExtensionSDK } from '@contentful/app-sdk';
import {
  Heading,
  Form,
  TextInput,
  FormControl,
  Stack,
} from '@contentful/f36-components';
import { useSDK } from '@contentful/react-apps-toolkit';

interface ConfigParams {
  name: string;
  label: string;
  helpText: string;
  inputType?: string;
}

const configParams: Array<ConfigParams> = [
  {
    name: 'deployToken',
    label: 'Deploy Token',
    helpText: 'The token used to authenticate with the API proxy',
    inputType: 'password',
  },
  {
    name: 'repoName',
    label: 'Repo Name',
    helpText: 'The name of the GitHub repository',
  },
  {
    name: 'repoOwner',
    label: 'Repo Owner',
    helpText: 'The owner of the GitHub repository',
  },
  {
    name: 'workflowId',
    label: 'Workflow ID',
    helpText: 'The GitHub workflow that will be dispatched',
  },
  {
    name: 'gitBranch',
    label: 'Git Branch',
    helpText: 'The branch that will be deployed when a workflow is triggered',
  },
  {
    name: 'deployUrl',
    label: 'Deploy URL',
    helpText: 'The URL where deployments can be viewed',
  },
];

export type AppInstallationParameters = {
  [key: string]: string;
};

const ConfigScreen = () => {
  const [parameters, setParameters] = useState<AppInstallationParameters>({});
  const sdk = useSDK<AppExtensionSDK>();

  const onConfigure = useCallback(async () => {
    const currentState = await sdk.app.getCurrentState();

    return {
      parameters,
      targetState: currentState,
    };
  }, [sdk, parameters]);

  useEffect(() => {
    sdk.app.onConfigure(() => onConfigure());
  }, [sdk, onConfigure]);

  useEffect(() => {
    (async () => {
      const currentParameters: AppInstallationParameters | null =
        await sdk.app.getParameters();

      if (currentParameters) {
        setParameters(currentParameters);
      }

      sdk.app.setReady();
    })();
  }, [sdk]);

  return (
    <Stack flexDirection="column" style={{ margin: '2rem' }}>
      <Form>
        <Heading>App Config</Heading>
        {configParams.map(({ name, label, helpText, inputType }) => (
          <FormControl key={name}>
            <FormControl.Label>{label}</FormControl.Label>
            <TextInput
              value={parameters[name] || ''}
              type={inputType ?? 'text'}
              name={name}
              onChange={(e) =>
                setParameters((params) => ({
                  ...params,
                  [name]: e.target.value,
                }))
              }
            />
            <FormControl.HelpText>{helpText}</FormControl.HelpText>
          </FormControl>
        ))}
      </Form>
    </Stack>
  );
};

export default ConfigScreen;
