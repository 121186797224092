import type { SidebarExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { Spinner, Flex } from '@contentful/f36-components';
import { Octokit } from '@octokit/rest';
import { useMutation, useQuery } from 'react-query';
import { useCallback, useMemo, useState } from 'react';
import { DeployLink } from '../components/DeployLink';
import { DeployButton } from '../components/DeployButton';
import { DeployStatus } from '../components/DeployStatus';
import type { DeploymentState } from '../types';

const Sidebar = () => {
  const sdk = useSDK<SidebarExtensionSDK>();
  const [deploymentState, setDeploymentState] =
    useState<DeploymentState>('notRunning');

  const octokit = useMemo(() => {
    return new Octokit({
      auth: sdk.parameters.installation.deployToken,
      baseUrl: '/api',
    });
  }, [sdk.parameters.installation]);

  const lastDeployQuery = useQuery(
    'lastWorkflowRun',
    () =>
      octokit.rest.actions.listWorkflowRuns({
        owner: sdk.parameters.installation.repoOwner,
        repo: sdk.parameters.installation.repoName,
        workflow_id: sdk.parameters.installation.workflowId,
        per_page: 3,
      }),
    {
      refetchInterval: (data) => {
        if (!data) return false;
        const latestRun = data.data.workflow_runs[0];

        switch (deploymentState) {
          case 'waitingToStart':
            if (latestRun.conclusion === null) {
              setDeploymentState('inProgress');
            }
            return 1500;
          case 'inProgress':
            if (latestRun.conclusion !== null) {
              setDeploymentState('notRunning');
              return false;
            }
            return 5000;
          case 'notRunning':
            return false;
        }
      },
    }
  );

  const deployMutation = useMutation(() =>
    octokit.rest.actions.createWorkflowDispatch({
      owner: sdk.parameters.installation.repoOwner,
      repo: sdk.parameters.installation.repoName,
      workflow_id: sdk.parameters.installation.workflowId,
      ref: sdk.parameters.installation.gitBranch,
    })
  );

  const deploy = useCallback(async () => {
    setDeploymentState('waitingToStart');
    deployMutation.mutate();
    lastDeployQuery.refetch();
  }, [lastDeployQuery, deployMutation]);

  if (lastDeployQuery.isLoading || !lastDeployQuery.data) {
    return (
      <>
        Loading deployment status <Spinner />
      </>
    );
  }

  return (
    <Flex flexDirection="column" gap="spacingS">
      <DeployButton
        recentDeployments={lastDeployQuery.data?.data}
        deploy={deploy}
        deploymentState={deploymentState}
      />
      {deploymentState === 'notRunning' && (
        <>
          <DeployStatus recentDeployments={lastDeployQuery.data?.data} />
          <DeployLink />
        </>
      )}
    </Flex>
  );
};

export default Sidebar;
