import type { SidebarExtensionSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { Button } from '@contentful/f36-components';
import { useCallback } from 'react';
import type { DeploymentState, RecentDeployments } from '../types';
import { useEntryIsDeployed } from '../hooks/useEntryIsDeployed';
import { useBuildDeployUrl } from '../hooks/useBuildDeployUrl';

interface DeployButtonProps {
  deploymentState: DeploymentState;
  recentDeployments: RecentDeployments;
  deploy: () => void;
}

export const DeployButton = ({
  deploymentState,
  recentDeployments,
  deploy,
}: DeployButtonProps) => {
  const sdk = useSDK<SidebarExtensionSDK>();
  const entryIsDeployed = useEntryIsDeployed(recentDeployments);
  const buildDeployUrl = useBuildDeployUrl();

  const handleClick = useCallback(async () => {
    if (entryIsDeployed) {
      const shouldDeploy = await sdk.dialogs.openConfirm({
        title: 'Are you sure?',
        message:
          'Entry has not been updated since the last deploy. Deploy anyway?',
        confirmLabel: 'Deploy',
      });
      if (!shouldDeploy) return;
    }
    deploy();
  }, [sdk.dialogs, deploy, entryIsDeployed]);

  return (
    <Button
      onClick={handleClick}
      isFullWidth
      isLoading={deploymentState !== 'notRunning'}
      isDisabled={deploymentState !== 'notRunning'}
      {...(
        {
          waitingToStart: {
            variant: 'secondary',
            children: 'Deployment Starting',
          },
          inProgress: {
            variant: 'secondary',
            children: 'Deployment in Progress',
          },
          notRunning: {
            variant: 'primary',
            children: `Deploy to ${buildDeployUrl().host}`,
          },
        } as const
      )[deploymentState]}
    />
  );
};
